/*
 * Object fit
 * Usage:
   @include object-fit(cover, top);
 */

@mixin object-fit($fit:cover, $position:center) {
    img {
        object-fit: $fit;
        object-position: $position;
    }
}