.project{
    padding: 4.8rem 0;

    &__main{
        @include wrap;
    }

    &__head{
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100vh;
        padding-top: 15rem;

        @include bp-max-width($bp-tablet) {
            position: relative;
            padding-top: 0;
            height: inherit;
            margin-bottom: 5rem;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 100px;

        @include bp-max-width($bp-tablet) {
            display: none;
        }
    }

    &__next{
        @include reset-style-button;
        display: flex;
        align-items: center;
        color: $color-primary;
        font-size: 1rem;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 1px;

        @include event{
            div{
                background-color: $color-main;
            }
            svg{
                stroke: $color-grey600;
            }
        }

        div, svg{
            transition: all 300ms ease-in-out;
        }

        div{
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            border: 1px solid $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1.4rem;

            svg{
                stroke: $color-primary;
                width: 1.6rem;
                stroke-width: 4px;
            }
        }
    }

    &__category{
        display: flex;
        flex-direction: column;
        margin-top: 12rem;
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1;

        @include after{
            width: 200%;
            height: 1px;
            background-color: white;
            margin-top: 4px;
        }
    }

    &__title{
        white-space: nowrap;
        margin-top: 1rem;
    }

    &__item{
        position: relative;
        max-width: 100%;

        @include bp-max-width($bp-tablet) {
            width: 100%;
        }

        @include not-last-child{
            margin-bottom: 5rem;
        }
    }

    &__index{
        display: flex;
        font-size: 6.5rem;
        line-height: 1;
        position: absolute;
        bottom: 0;
        right: 0;
        font-style: italic;
        transform: translate(-1.4rem, 50%);

        @include bp-max-width($bp-tablet) {
            display: none;
        }
    }

    &__picture{
        width: 100%;
        @include aspect-ratio(630, 440);
        @include object-fit;
    }
}
