/*
 * After
 * Usage:
   @include after(){
      @content
   };
 */

@mixin after(){
    &:after{
        content: '';
        @content
    }
}
