/*
 * Auto generate classes for title and auto generate size title in text-content
 * class generate : '_#{name}'
*/
$font-weight: ("400", "700");
$font-size-default: 1rem;
$font-sizes: (
    "extra-big" : 9.2rem,
    "big" : 6.5rem,
    "medium" : 5.6rem,
    "default" : $font-size-default,
    "small" : 1rem,
    "tiny" : 1.4rem,
);
$colors: ();

@mixin title-base(){
    margin: 0;
    font-family: $typography-font-family-main;
    font-size: $font-size-default;
    line-height: 1.1;
    font-weight: 300;
    color: $color-main;

    @include bp-max-width($bp-mobile) {
        font-size: calc(#{$font-size-default} / 1.05);
    }
}

.title{
    @include title-base;

    /* Font-size */
    @each $name, $size in $font-sizes {
        &._#{$name}{
            font-size: #{$size};

            @include bp-max-width($bp-mobile) {
                @if $size > 5{
                    font-size: calc(#{$size} / 1.3);
                }@else{
                    font-size: calc(#{$size} / 1.05);
                }
            }
        }
    }

    /* Font-weight */
    @each $weight in $font-weight {
        &._#{$weight}{
            font-weight: #{$weight};
        }
    }

    /* Colors */
    @each $name, $color in $colors {
        &._#{$name}{
            color: $color;
        }
    }

    /* Utils */
    &._center{
        text-align: center;
    }

    &._uppercase{
        text-transform: uppercase;
    }

    &._italic{
        font-style: italic;
    }

    &._letter-spacing{
        letter-spacing: 1px;
    }

    &._section-title{
        margin: 0 0 6.4rem auto;
        color: $color-grey400;
        font-weight: 600;

        @include bp-max-width($bp-mobile) {
            display: none;
        }
    }

    strong{
        font-weight: 400;
        text-transform: uppercase;
        font-style: italic;
    }
}
