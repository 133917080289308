/*
 * Grid
 * Usage:
   @include grid(20px, 10px);
   @include grid-item(20px, 10px);
 */

@mixin grid($gutter: $grid-default-gutter, $gutter-mobile: $grid-default-gutter-mobile){
    --gutter: #{$gutter};
    --gutter-mobile: #{$gutter-mobile};
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--gutter) * -.05rem);
    margin-right: calc(var(--gutter) * -.05rem);
    @include bp-max-width($bp-mobile) {
        margin-left: calc(var(--gutter-mobile) * -.05rem);
        margin-right: calc(var(--gutter-mobile) * -.05rem);
    }
}

@mixin grid-item($gutter: $grid-default-gutter, $gutter-mobile: $grid-default-gutter-mobile) {
    padding-left: calc(var(--gutter) * .05rem);
    padding-right: calc(var(--gutter) * .05rem);
    @include bp-max-width($bp-mobile) {
        padding-left: calc(var(--gutter-mobile) * .05rem);
        padding-right: calc(var(--gutter-mobile) * .05rem);
    }
}

@mixin make-grid-class($max-column, $class-prefix, $class-suffix: '') {
    @for $i from 1 through $max-column {
        #{'.'}#{$class-prefix}#{$i}-#{$max-column}#{$class-suffix} {
            @include grid-item();
            width: calc(100% / $max-column) * $i;
            @if ($i == $max-column) {
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
        #{'.'}#{$class-prefix}#{$i}-#{$max-column}--push-l#{$class-suffix} {
            $col-width: calc(100% / $max-column) * $i;
            margin-left: calc(#{$col-width});
        }
        #{'.'}#{$class-prefix}#{$i}-#{$max-column}--push-r#{$class-suffix} {
            $col-width: calc(100% / $max-column) * $i;
            margin-right: $col-width;
        }
    }
}

@mixin make-grid-classes($max-column, $class-prefix: 'grid__i--') {
    @include make-grid-class($max-column, $class-prefix);
    @each $breakpoint in $breakpoints {
        $bp-name: nth($breakpoint, 1);
        $bp-width: nth($breakpoint, 2);
        @include bp-max-width($bp-width) {
            @include make-grid-class($max-column, $class-prefix, '\\@' + $bp-name);
        }
    }
}
