/*
 * Before
 * Usage:
   @include before(){
      @content
   };
 */

@mixin before(){
    &:before{
        content: '';
        @content
    }
}
