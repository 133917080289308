/*
 * Reset first last child
 * Usage:
 * @include reset-first-last();
 */

@mixin reset-first-last() {

  &:first-child{
    margin-top: 0;
    padding-top: 0;
  }

  &:last-child{
    margin-bottom: 0;
    padding: 0;
  }

}