html {
    font-size: 62.5%;

    @include bp-max-width($bp-desktop) {
        font-size: 52.5%;
    }

    @include bp-max-width($bp-mobile) {
        font-size: 48%;
    }
}