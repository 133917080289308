/*
 * Triangle
 * Usage:
   @include css-triangle(red, up, 10px, 6px);
 */

@mixin css-triangle($color, $direction, $oneSize: 10px, $duoSize: 6px){
  display: block;
  width: 0;
  height: 0;

  @if $direction == down {
    border-left: $duoSize solid transparent;
    border-right: $duoSize solid transparent;
    border-top: $oneSize solid $color;
  } @else if $direction == up {
    border-left: $duoSize solid transparent;
    border-right: $duoSize solid transparent;
    border-bottom: $oneSize solid $color;
  } @else if $direction == right {
    border-top: $duoSize solid transparent;
    border-bottom: $duoSize solid transparent;
    border-left: $oneSize solid $color;
  } @else if  $direction == left {
    border-top: $duoSize solid transparent;
    border-bottom: $duoSize solid transparent;
    border-right: $oneSize solid $color;
  }
}
