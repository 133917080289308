/*
 * Aspect ratio
 * Usage:
 * Use on parent image
   @include aspect-ratio(2, 1, img);
*/
@mixin aspect-ratio($width, $height, $target-el: img) {
    position: relative;
    display: block;

    &:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    #{$target-el} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}