.text-content{
    --font-size: 1.8rem;

    &._big{
        --font-size: 3.6rem;
    }

    &._max-width{
        width: 74rem;

        @include bp-max-width($bp-desktop) {
            width: 68rem;
        }

        @include bp-max-width($bp-tablet) {
            max-width: 100%;
        }
    }

    &, p{
        font-size: var(--font-size);
        line-height: 1.2;
    }

    strong{
        font-weight: 400;
        text-transform: uppercase;
        font-style: italic;
    }
}
