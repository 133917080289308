img,
video,
canvas,
iframe {
  max-width: 100%;
}

img {
  display: block;
  width: 100%;
  filter: grayscale(100);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
}

img:hover{
  filter: inherit;
}

.legend{
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 0 0;
    font-size: 1.2rem;
}
