.u-flex{
    display: flex;
}

.u-justify-space-between{
    justify-content: space-between;
}

.u-align-i-center{
    align-items: center;
}

.u-text-center {
    text-align: center;
}

.u-full-width {
    width: 100%;
}

.u-text-vertical {
    writing-mode: vertical-rl;
    text-orientation: sideways;
    transform: rotate(180deg);
}

.u-p-relative{
    position: relative;
}
