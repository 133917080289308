.contact{
    padding: 2.4rem 0 12rem;

    &__main{
        @include wrap;
    }

    &__content{
        max-width: 60rem;
    }

    &__arrow{
        width: 4rem;
        stroke: white;
        stroke-width: 2px;
        margin: 0 4.5rem .8rem 0;
        align-self: flex-start;
    }

    &__links{
        display: flex;
        align-items: center;
        margin: 22rem 0 0;

        @include bp-max-width($bp-mobile) {
            margin: 16rem 0 0;
        }
    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        margin: -1.25rem;

        @include bp-max-width($bp-mobile) {
            flex-direction: column;
            align-items: flex-start;
            margin: -1rem 0;
        }
    }

    &__item{
        margin: 1.25rem;

        @include bp-max-width($bp-mobile) {
            margin: 1rem 0;
        }

    }

    &__link{
        line-height: 1;
        font-size: 3.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include transition;

        @include after{
            width: 100%;
            height: 1px;
            background-color: white;
            margin-top: 0.5rem;
            transform: scaleX(0);
            transform-origin: right;
            left: 0;
            transition: transform 0.8s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.4s cubic-bezier(0.22, 1, 0.36, 1);
            // @include transition(300ms);

            @include bp-max-width($bp-mobile) {
                display: none;
            }
        }

        @include event{
            // color: $color-primary;
            

            &:after{
                transform: scaleX(1);
                transform-origin: left;
                background-color: $color-primary;
            }
        }
    }

    .grid{
        justify-content: flex-end;
    }
}
