/*
 * Gradient
 * Usage:
   @include gradient("horizontal", red, blue);
 */

@mixin gradient($direction: horizontal, $color1: black, $color2: black){
    @if $direction == "horizontal" {
        background: linear-gradient(90deg, $color1 0%, $color2 100%);
    }
    @else if $direction == "vertical" {
        background: linear-gradient(180deg, $color1 0%, $color2 100%);
    }
}
