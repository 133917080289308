body {
    font-family: $typography-font-family-main;
    font-size: $typography-font-size-main;
    line-height: $typography-line-height-main;
    font-weight: $typography-font-weight-main;
    color: $color-main;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    margin: 0;
    background-color: $color-grey500;

    * {
        box-sizing: border-box;
    }

    &.has-no-scroll{
        overflow: hidden;
    }

    *::selection{
        background-color: $color-primary;
        color: $color-grey500;
    }

    --space-left-container: 45%;

    @include bp-max-width($bp-tablet) {
        --space-left-container: 30%;
    }
}
